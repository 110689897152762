import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Stack, Typography, Box, MenuItem, Alert } from '@mui/material';
import { EmployeeAutocomplete } from '../components/EmployeeAutocomplete';
import { CompletionDate } from '../components/CompletionDate';
import { SelectField } from '../components/SelectField';
import { SwitchInput } from '../components/SwitchInput';
import { RadioButtonGroup } from '../components/RadioButtonGroup';
import { useAPI } from '../Providers/APIProvider';

export const RequestInformation = ({ dashboardView, intakeData, errors }) => {
  const { watch } = useFormContext();
  const { getEmployees, getIndustries } = useAPI();
  const [options, setOptions] = useState([]);
  const [industry, setIndustries] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      const employees = await getEmployees();
      if (!employees) {
        return [];
      }
      setOptions(employees);
      return employees;
    };
    getOptions();
  }, []);

  useEffect(() => {
    const getTeams = async () => {
      const industries = await getIndustries();
      setIndustries(industries);
      return industries;
    };
    getTeams();
  }, []);

  const industryGroup = !dashboardView ? watch('industry') : null;
  const escalateRequest = !dashboardView ? watch('escalateRequest') : null;
  const itVerticalLeadConversation = !dashboardView ? watch('itVerticalLeadConversation') : null;

  return (
    <Box sx={{ padding: '1rem' }}>
      <Stack
        sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem', minHeight: '100px' }}
        spacing={2}
        justifyContent="space-between"
      >
        {dashboardView ? (
          <Typography variant="body1" paragraph gutterBottom>
            Champion(s) for this request:{' '}
            <span style={{ fontWeight: 900 }}>{intakeData?.championNames}</span>
          </Typography>
        ) : (
          <Typography variant="body1" paragraph gutterBottom>
            Please provide a a champion for this request. A champion is an individual or list of
            individuals responsible for the request and evaluation of software.
            <span style={{ color: 'red' }}>*</span>
          </Typography>
        )}
        {!dashboardView && (
          <EmployeeAutocomplete
            name="champions"
            label="Champions"
            options={options}
            getOptionLabel={(option) => option.name}
            error={errors?.champions !== undefined}
            readOnly={dashboardView}
            helperText={errors?.champions?.message}
          />
        )}
      </Stack>
      <Stack direction="row" spacing={4} sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
        <Stack sx={{ width: '100%', minHeight: '100px' }} spacing={2}>
          {dashboardView ? (
            <Typography variant="body1" paragraph gutterBottom>
              Senior Director/VP for approval of this request: <br />
              <span style={{ fontWeight: 900 }}>{intakeData?.vpTechCabLeader}</span>
            </Typography>
          ) : (
            <Typography variant="body1" paragraph gutterBottom>
              Senior Director or VP for request approval<span style={{ color: 'red' }}>*</span>
            </Typography>
          )}
          {!dashboardView && (
            <EmployeeAutocomplete
              name="vpTechCabLeaders"
              label="Senior Approval"
              options={options}
              getOptionLabel={(option) => option?.name}
              readOnly={dashboardView}
              error={errors?.vpTechCabLeaders !== undefined}
              helperText={errors?.vpTechCabLeaders?.message}
            />
          )}
        </Stack>
        <Stack sx={{ width: '100%', minHeight: '100px' }} spacing={2}>
          <Typography variant="body1" paragraph gutterBottom>
            What type of request is this for RV technology?<span style={{ color: 'red' }}>*</span>
          </Typography>
          <SelectField
            name="typeOfRequest"
            label="Request Type"
            error={errors.typeOfRequest !== undefined}
            helperText={errors.typeOfRequest?.message}
            readOnly={dashboardView}
          >
            <MenuItem value="new">New - Onboard new technology</MenuItem>
            <MenuItem value="modify">Modify - Update existing technology</MenuItem>
            <MenuItem value="remove">Remove - Change status of tech to depreciate</MenuItem>
          </SelectField>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        sx={{ paddingTop: '1rem', paddingBottom: '1rem', minHeight: '100px' }}
      >
        <Stack sx={{ width: '100%', minHeight: '100px' }} spacing={2}>
          <Typography variant="body1" paragraph gutterBottom>
            To properly allocate the cost of this technology, please select an industry group.
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <SelectField
            name="industry"
            label="Industry Group"
            error={errors.industry !== undefined}
            helperText={errors.industry?.message}
            readOnly={dashboardView}
          >
            {industry.map((item) => (
              <MenuItem key={item.industry} value={item.industry}>
                {item.industry}
              </MenuItem>
            ))}
          </SelectField>
        </Stack>
        {!dashboardView && (
          <Stack sx={{ width: '100%', minHeight: '100px', height: '200px' }} spacing={2}>
            <SwitchInput
              name="itVerticalLeadConversation"
              defaultValue={false}
              label="Have you had a conversation with the IT Vertical Lead about this request?"
            />
            {!itVerticalLeadConversation && (
              <Alert severity="info">
                Please make sure that you have a conversation with the IT Vertical Lead about this
                request prior to submission.{' '}
                {industry.find((item) => item.industry === industryGroup) &&
                  `Our records indicate that the industry lead
            for this group is
            ${industry.find((item) => item.industry === industryGroup)?.industryLeader}.
            Please make sure they are aware of this request.`}
              </Alert>
            )}
          </Stack>
        )}
      </Stack>
      <Stack
        direction="row"
        spacing={4}
        sx={{ paddingTop: '1rem', paddingBottom: '1rem', minHeight: '100px', height: '200px' }}
      >
        <Stack
          spacing={2}
          justifyContent="space-between"
          sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}
        >
          <Typography variant="body1" paragraph gutterBottom>
            Please provide the date this needs to be completed by. Normal requests typically are
            completed within 4 weeks depending on the nature of the request
          </Typography>
          <CompletionDate
            name="dateCompleted"
            placeholderText="Select date"
            disabled={dashboardView}
            // Once we confirm if we want to keep the date on the form then we can keep this or not
            // apiDate={intakeData.dateToBegin}
          />
        </Stack>
        <Stack
          spacing={2}
          justifyContent="space-between"
          sx={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}
        >
          <SwitchInput
            name="escalateRequest"
            label="Does this request need to be expidited?"
            disabled={dashboardView}
            defaultChecked={dashboardView ? intakeData.escalateRequest : false}
          />
          {escalateRequest && (
            <Alert severity="info">
              {industry.find((item) => item.industry === industryGroup) &&
                `If this request needs to be expedited, we ask that you have your IT Vertical Lead(
            ${industry.find((item) => item.industry === industryGroup)?.industryLeader}).
            reach out to us directly`}
            </Alert>
          )}
          {dashboardView && intakeData?.escalateRequest && (
            <Alert severity="info">
              Our records indicate you need to expedite this request. Please note that we can only
              expidite this request after the IT lead for your vertical has reached out to us.
            </Alert>
          )}
        </Stack>
      </Stack>
      <Stack spacing={2} alignItems="flex-start" sx={{ minHeight: '100px' }}>
        <Typography variant="body1" paragraph gutterBottom>
          Software type<span style={{ color: 'red' }}>*</span>
        </Typography>
        <RadioButtonGroup
          name="softwareType"
          error={errors.softwareType !== undefined}
          helperText={errors.softwareType?.message}
          disabled={dashboardView}
          labelValue={[
            { value: 'commercial', label: 'Commercial' },
            { value: 'open source', label: 'Open Source' },
          ]}
        />
      </Stack>
    </Box>
  );
};

RequestInformation.defaultProps = {
  dashboardView: false,
  intakeData: {},
  errors: {},
};

RequestInformation.propTypes = {
  dashboardView: PropTypes.bool,
  intakeData: PropTypes.object,
  errors: PropTypes.object,
};
