import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

export const BasicTextField = ({
  name,
  label,
  defaultValue,
  size,
  disabled,
  rows,
  multiline,
  required,
  error,
  helperText,
  readOnly,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          required={required}
          sx={{ width: '100%' }}
          disabled={disabled}
          multiline={multiline}
          size={size}
          rows={rows}
          label={label}
          error={error}
          helperText={helperText}
          inputProps={{ readOnly }}
        />
      )}
    />
  );
};

BasicTextField.defaultProps = {
  defaultValue: '',
  label: '',
  size: 'small',
  disabled: false,
  rows: 1,
  multiline: false,
  required: false,
  error: false,
  helperText: '',
  readOnly: false,
};

BasicTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
};
