import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';

export const SwitchInput = ({ name, label, defaultChecked, disabled }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormGroup {...field}>
          <FormControlLabel
            disabled={disabled}
            control={<Switch defaultChecked={defaultChecked} />}
            label={label}
          />
        </FormGroup>
      )}
    />
  );
};

SwitchInput.defaultProps = {
  defaultChecked: false,
  label: '',
  disabled: false,
};

SwitchInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};
