import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const CompletionDate = ({ name, defaultValue, placeholderText, disabled /* apiDate */ }) => {
  const { control } = useFormContext();
  const date = new Date();
  // This is commented out for now since currently we don't list the completion date in the database unless it needs to be expidited.
  // Waiting to here from procurement team on if we want to keep this question.
  // const dateSelected = disabled && new Date(apiDate);
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      sx={{ width: '100%' }}
      render={({ field: { onChange, value: fieldValue } }) => (
        <DatePicker
          {...fieldValue}
          placeholderText={placeholderText}
          sx={{ width: '100%', color: 'black' }}
          onChange={(date) => onChange(date)}
          selected={fieldValue}
          disabled={disabled}
          minDate={date.setDate(date.getDate() + 28)}
          className="form-control"
        />
      )}
    />
  );
};

CompletionDate.defaultProps = {
  defaultValue: '',
  placeholderText: '',
  disabled: false,
  apiDate: '',
};

CompletionDate.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  placeholderText: PropTypes.string,
  disabled: PropTypes.bool,
  apiDate: PropTypes.any,
};
