import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

export const EmployeeAutocomplete = ({
  name,
  label,
  defaultValue,
  helperText,
  error,
  options,
  getOptionLabel,
  readOnly,
}) => {
  const { control } = useFormContext();

  const uniqueOptions = options.filter(
    (item, index) => options.findIndex((i) => i.email === item.email) === index
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          size="small"
          options={uniqueOptions}
          readOnly={readOnly}
          sx={{ width: '100%' }}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          onChange={(event, item) => {
            field.onChange(item);
          }}
          value={field.value}
          renderOption={(props, option) => (
            <li {...props} key={option.email}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} error={error} helperText={helperText} label={label} />
          )}
        />
      )}
    />
  );
};

EmployeeAutocomplete.defaultProps = {
  defaultValue: '',
  label: '',
  error: false,
  helperText: '',
  readOnly: false,
};

EmployeeAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  readOnly: PropTypes.bool,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
};
