import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import { CheckBoxLabel } from './CheckBoxLabel';

export const CheckBoxGroup = ({ children }) => {
  return <FormGroup>{children}</FormGroup>;
};

CheckBoxGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export const CheckBoxItem = ({ labelItem, name, value, children, disabled, required, checked }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value: fieldValue } }) => (
        <FormControlLabel
          sx={{
            paddingBottom: '1rem',
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-label.Mui-disabled': {
              color: disabled && checked ? '#000' : '',
            },
          }}
          disabled={disabled}
          control={
            <Checkbox
              checked={fieldValue || checked || false}
              value={value}
              sx={{ paddingTop: '2px' }}
            />
          }
          label={<CheckBoxLabel label={labelItem}>{children}</CheckBoxLabel>}
          onChange={(event) => onChange(event.target.checked)}
          required={required}
        />
      )}
    />
  );
};

CheckBoxItem.defaultProps = {
  labelItem: '',
  disabled: false,
  checked: false,
  required: false,
};

CheckBoxItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  labelItem: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  required: PropTypes.bool,
};
