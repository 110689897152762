import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Typography, Container, Box, Tabs, Tab, Link, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import PageContainer from '../container/PageContainer';
import { RequestInformation } from '../pageComponents/RequestInformation';
import { VendorInformation } from '../pageComponents/VendorInformation';
import { SoftwareInformation } from '../pageComponents/SoftwareInformation';
import { ComplianceInformation } from '../pageComponents/ComplianceInformation';
import { useAPI } from '../Providers/APIProvider';

const CustomTabPanel = ({ children, tabInfo, value, index, ...props }) => {
  const { content } = tabInfo;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...props}
    >
      {value === index && <Box>{content}</Box>}
    </div>
  );
};

CustomTabPanel.defaultProps = {
  children: null,
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  tabInfo: PropTypes.object.isRequired,
};

export const Dashboard = () => {
  const { workflowId } = useParams();
  const { getIntakeForm } = useAPI();
  const [value, setTabValue] = useState(0);
  const [intakeData, setIntakeData] = useState({});

  const methods = useForm({
    defaultValues: {
      ...intakeData,
    },
  });

  useEffect(() => {
    const revertToString = ['proofOfConcept', 'anotherTeamInvolved', 'certified', 'singleSignOn'];
    const revertToStrBoolean = [
      'isDataProcessedExternally',
      'hasNetworkInterconnectivity',
      'needsRvSystemAccess',
      'systemsSuppliedByVendor',
      'isRvDataStoredAtVendor',
      'needsRvPhysicalAccess',
    ];
    const getPreviousAnswers = async () => {
      const previousAnswers = await getIntakeForm(workflowId);
      Object.keys(previousAnswers).forEach((fieldName) => {
        if (previousAnswers[fieldName] === null || previousAnswers[fieldName] === 'Unknown') {
          methods.setValue(fieldName, (previousAnswers[fieldName] = ''));
        }

        if (fieldName === 'desktopInstalled') {
          methods.setValue(fieldName, (previousAnswers[fieldName] = ''));
        }

        if (revertToStrBoolean.includes(fieldName)) {
          if (previousAnswers[fieldName] === 1) {
            methods.setValue(fieldName, (previousAnswers[fieldName] = 'true'));
          }
          if (previousAnswers[fieldName] === 0) {
            methods.setValue(fieldName, (previousAnswers[fieldName] = 'false'));
          }
        }

        if (revertToString.includes(fieldName)) {
          if (previousAnswers[fieldName] === 1) {
            methods.setValue(fieldName, (previousAnswers[fieldName] = 'Yes'));
          }
          if (previousAnswers[fieldName] === 0) {
            methods.setValue(fieldName, (previousAnswers[fieldName] = 'No'));
          }
        }

        methods.setValue(fieldName, previousAnswers[fieldName]);
      });
      setIntakeData(previousAnswers);
    };
    getPreviousAnswers();
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabInfo = [
    {
      title: 'Request Information',
      content: <RequestInformation dashboardView intakeData={intakeData} />,
    },
    {
      title: 'Vendor Information',
      content: <VendorInformation dashboardView intakeData={intakeData} />,
    },
    {
      title: 'Software Information',
      content: <SoftwareInformation dashboardView />,
    },
    {
      title: 'Compliance Information',
      content: <ComplianceInformation dashboardView intakeData={intakeData} />,
    },
  ];

  return (
    <PageContainer>
      <Container disableGutters maxWidth="md" sx={{ marginTop: '2rem' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              marginTop: '2rem',
              marginBottom: '2rem',
              padding: '2rem',
              border: '1px solid ##bdbdbd',
              borderRadius: '5px',
              backgroundColor: '#f5f5f5',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }} variant="h6" component="h1" gutterBottom>
              Review Request Information
            </Typography>
            <Stack>
              <Typography variant="body1" gutterBottom>
                <span style={{ fontWeight: 'bold' }}>Workflow ID:</span> {workflowId}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <span style={{ fontWeight: 'bold' }}>Requestor Name:</span>{' '}
                {intakeData.requestorName}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <span style={{ fontWeight: 'bold' }}>Product Under Review:</span>{' '}
                {intakeData.nameOfTechnology}
              </Typography>
            </Stack>
            <Typography sx={{ marginTop: '2rem' }} variant="body2" paragraph gutterBottom>
              Here you can review the information for your request. If you have any questions, in
              regards to the status of your request, please visit the slack channel for updates.
              Addionally, you can contact the procurement team at{' '}
              <Link href="email:procurement@redventures.com">procurement@redventures.com</Link>
            </Typography>
          </Box>
          <FormProvider {...methods}>
            <form>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="dashboard information">
                  <Tab label="Request Information" />
                  <Tab label="Vendor Information" />
                  <Tab label="Software Information" />
                  <Tab label="Compliance Information" />
                </Tabs>
              </Box>
              {tabInfo.map((tab, index) => (
                <CustomTabPanel key={tab.title} value={value} index={index} tabInfo={tab} />
              ))}
            </form>
          </FormProvider>
        </Box>
      </Container>
    </PageContainer>
  );
};
