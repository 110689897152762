import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import {
  RadioGroup,
  Stack,
  FormControlLabel,
  Radio,
  FormControl,
  FormHelperText,
} from '@mui/material';

// RadioButtonGroup
export const RadioButtonGroup = ({
  name,
  error,
  disabled,
  helperText,
  defaultValue,
  labelValue = [],
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl error={error}>
          <RadioGroup
            {...field}
            name={name}
            onChange={(e) =>
              field.onChange(
                e.target.value === 'true' || e.target.value === 'false'
                  ? JSON.parse(e.target.value)
                  : e.target.value
              )
            }
          >
            <Stack direction="row">
              <FormControlLabel
                value={labelValue[0].value}
                disabled={disabled}
                control={<Radio />}
                label={labelValue[0].label}
                sx={{
                  '& .MuiFormControlLabel-label.Mui-disabled': {
                    color:
                      disabled && (field.value === labelValue[0].value || field.value === 1)
                        ? '#000'
                        : '',
                  },
                }}
              />
              <FormControlLabel
                value={labelValue[1].value}
                disabled={disabled}
                control={<Radio />}
                label={labelValue[1].label}
                sx={{
                  '& .MuiFormControlLabel-label.Mui-disabled': {
                    color:
                      disabled && (field.value === labelValue[1].value || field.value === 0)
                        ? '#000'
                        : '',
                  },
                }}
              />
            </Stack>
          </RadioGroup>
          <FormHelperText sx={{ marginLeft: '0' }}>{helperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

RadioButtonGroup.defaultProps = {
  defaultValue: '',
  error: false,
  helperText: '',
  disabled: false,
};

RadioButtonGroup.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  labelValue: PropTypes.arrayOf(PropTypes.object).isRequired,
};
