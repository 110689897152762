import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import AppContainer from './container/AppContainer';
import { LandingPage } from './pages/LandingPage';
import { Request } from './pages/Request';
import { Dashboard } from './pages/Dashboard';

const Router = () => {
  return (
    <BrowserRouter>
      <AppContainer>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/request" element={<Request />} />
          <Route path="/request/:workflowId" element={<Dashboard />} />
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
};

export default Router;
